<template>
  <div class="job-lists-wrapper">
    <div class="banner">
      <img src="@/assets/image/banner1.png" />
    </div>
    <job-list-template ref="jobList" />
    <ExitApplication v-if="popUp" @ensureFn="ensureFn" @cancelfn="cancelfn" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import JobListTemplate from '@/components/jobListTemplate'
import ExitApplication from '@/components/ExitApplication.vue'
export default {
  components: {
    JobListTemplate,
    ExitApplication //退出应用组件
  },
  data() {
    return {
      popUp: false //控制退出应用的组件显示
    }
  },
  computed: {
    ...mapState(['channel', 'token'])
  },
  created() {
    if (this.token) {
      this.$refs.jobList.getInterviewTrainingCategoryFn()
    } else {
      const channel = localStorage.getItem('channel')
      if (channel) {
        this.$router.push(`/?channel=${channel}`)
      } else {
        this.$router.push(`/`)
      }
    }
    document.onkeydown = function (e) {
      if (e.keyCode === 27) _this.onOutPageFn()
    }
  },
  methods: {
    // 跳转页面
    onSkipPageFn(url, item) {
      this.$router.push({
        query: {
          id: item.id
        },
        path: url
      })
    },
    // 监听打开退出弹窗
    onOutPageFn() {
      this.popUp = true
    },
    //当用户点击确定退出
    ensureFn() {
      history.go(-history.length + 1)
    },
    //当用户点击取消关闭退出应用的弹窗
    cancelfn(data) {
      this.popUp = data
    }
  }
}
</script>

<style lang="stylus" scoped>
.job-lists-wrapper
  width 100%
  height auto
</style>
